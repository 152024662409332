/*********************Colors*********************/
$color-yellow: #e0a727;
$color-bright-red: #a80532;
$color-medium-red: #930c30;
$color-dark-red: #75152b;
$color-off-white: #fffdfa;
$color-cream: #fff8f0;
$color-light-peach: #f9e6cd;
$color-peach: #f4d4ab;
$color-beige: #e6b87d;
$color-orange: #c87622;
$color-brown: #665239;
$color-charcoal: #342a22;

/*********************Fonts*********************/
$font-korolev: "korolev", sans-serif;
$font-korolev-compressed: "korolev-compressed", sans-serif;
$font-korolev-condensed: "korolev-condensed",sans-serif;
$font-merriweather: "merriweather", serif;

$font-weight-bold: 700;
$font-weight-normal: 500;
$font-weight-light: 300;
