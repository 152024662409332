@import "_logic.scss";

html,
body {
    height: 100%;
}

ul {
    margin: 0;
    padding: 0;
}

li {
    list-style: none;
}

nav li {
    display: inline-block;
}

a {
    text-decoration: none;
    color: initial;
}

.italic {
    font-style: italic;
}

.bold {
    font-weight: bold !important;
}

.normal {
    font-weight: normal;
}

.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
}

.icon-twitter {
    width: 0.9285714285714285em;
}

.icon-instagram {
    width: 0.8571428571428571em;
}

.icon-facebook {
    height: 17px;
}

.icon-twitter,
.icon-instagram {
    height: 20px;
}

.breadcrumbs {
    text-transform: uppercase;
    font: $font-weight-light 22px $font-korolev-condensed;

    a {
        color: $color-dark-red;
    }

    .arrow-container,
    .arrow-container:hover {
        background: none;
    }

    .arrow {
        top: 13px;
    }
}

.tabs-container {

    .tab-content > div {
        display: none;
    }

    .tab-content > div.active {
        display: flex;
    }
}

.left-15 {
    padding-left: 15px;
}

.top-50 {
    margin-top: 50px !important;
}

.btm-20 {
    margin-bottom: 20px !important;
}

.btm-50 {
    margin-bottom: 50px !important;
}

@media only screen and (max-width: 768px) {
    .btm-sm-20 {
        margin-bottom: 20px !important;
    }

    .btm-sm-50 {
        margin-bottom: 50px !important;
    }
}