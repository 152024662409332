﻿/* - Font Variables - */
.regular {
    font-family: "korolev", sans-serif;
}

.condensed {
    font-family: "korolev-condensed", sans-serif;
}

.compressed {
    font-family: "korolev-compressed", sans-serif;
}

.thin {
    font-weight: 200;
}

.light {
    font-weight: 300;
}

.medium {
    font-weight: 500;
}

.bold {
    font-weight: 700;
}

.heavy {
    font-weight: 900;
}

/* - Color Variables - */
$shiraz: #a80532;
$claret: #75152B;
$sand: #e6b87d;
$ochre: #c87622;
$birch: #342e22;

/* - Animations - */
@keyframes color-1 {
    25% {
        color: $claret;
    }

    50% {
        color: $ochre;
    }

    75% {
        color: $sand;
    }

    100% {
        color: $shiraz;
    }
}

@keyframes color-2 {
    25% {
        color: $shiraz;
    }

    50% {
        color: $claret;
    }

    75% {
        color: $ochre;
    }

    100% {
        color: $sand;
    }
}

@keyframes color-3 {
    25% {
        color: $sand;
    }

    50% {
        color: $shiraz;
    }

    75% {
        color: $claret;
    }

    100% {
        color: $ochre;
    }
}

@keyframes color-4 {
    25% {
        color: $ochre;
    }

    50% {
        color: $sand;
    }

    75% {
        color: $shiraz;
    }

    100% {
        color: $claret;
    }
}

/* - Animation Classes - */
.color-1 {
    animation: .7s step-end color-1 forwards;
}

.color-2 {
    animation: .6s step-end color-2 forwards;
}

.color-3 {
    animation: .5s step-end color-3 forwards;
}

.color-4 {
    animation: .5s step-end color-4 forwards;
}

.color-1 {
    color: $shiraz;
}

.color-2 {
    color: $sand;
}

.color-3 {
    color: $ochre;
}

.color-4 {
    color: $claret;
}

.font-1 {
    @extend .regular;
}

.font-2 {
    @extend .condensed;
}

.font-3 {
    @extend .compressed;
}

.weight-1 {
    @extend .thin;
}

.weight-2 {
    @extend .light;
}

.weight-3 {
    @extend .medium;
}

.weight-4 {
    @extend .bold;
}

.weight-5 {
    @extend .heavy;
}
