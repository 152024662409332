.c-services-list {
  @include list-reset;
  display: flex;
  flex-wrap: wrap;

  &__item {
    flex: 1 0 17em;
    padding: 0.5em;
  }

  &--3-col {
    --gap: 1rem;
    gap: var(--gap);

    .c-services-list__item {
      padding: 0;
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;

      @include mq(48em) {
        flex-basis: auto;
        max-width: calc(33.333% - (2 / 3 * var(--gap)));
      }
    }
  }
}

.c-service {
  display: block;
  height: 100%;
  padding: 2em;
  background-color: $primary-color-lighter;
  text-align: center;
  font-weight: 600;
  color: #002a51; // not mapped to a variable
  text-decoration: none;

  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 6em;
    height: 6em;
    margin-bottom: 1em;
    border: 0.25em solid $accent-color;
    border-radius: 50%;
    background-color: white;

    img {
      width: 70%;
      height: auto;
    }
  }

  &__name {
    line-height: 1;
    font-size: 1.5em;
  }
}
