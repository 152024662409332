﻿/*Breakout Sections*/
.breakout-container {
    position: relative;

    &:before,
    &:after {
        content: "";
        position: absolute;
        display: block;
    }

    &--left {
        margin-right: -8.333%;

        &:before {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: translateX(-99%);
        }

        &:after {
            right: 0;
            bottom: 0;
            width: 30px;
            height: 30px;
            transform: translateX(100%);
        }
    }

    &--right {
        margin-left: -8.333%;

        &:before {
            left: 0;
            top: 0;
            width: 15px;
            height: 15px;
            transform: translateX(-100%);
        }

        &:after {
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            transform: translateX(100%);
        }
    }

    &--title-block {
        margin: 70px 8.33% 70px 0;
        height: auto;
        background-color: $color-cream;

        &:before {
            background-color: $color-cream;
        }

        .breakout-content {
            position: relative;

            &:before,
            &:after {
                position: absolute;
                left: calc(108.33% + 7.5px);
                content: "";
                display: block;
            }

            &:before {
                bottom: 30px;
                background-color: $color-light-peach;
                height: 15px;
                width: 15px;
            }

            &:after {
                bottom: 0;
                background-color: $color-peach;
                height: 30px;
                width: 30px;
            }
        }

        .breadcrumbs {
            padding-top: 50px;
        }

        h1 {
            margin: 20px 0 50px;
        }
    }

    &--event-title-block {
        margin: 70px 8.33% 0 0;
        height: auto;
        background-color: $color-cream;
        padding-bottom: 35px;

        &:before {
            background-color: $color-cream;
        }

        .breakout-content {
            position: relative;

            &:before,
            &:after {
                position: absolute;
                left: calc(108.33% + 7.5px);
                content: "";
                display: block;
            }

            &:before {
                bottom: -5px;
                background-color: $color-light-peach;
                height: 15px;
                width: 15px;
            }

            &:after {
                bottom: -35px;
                background-color: $color-peach;
                height: 30px;
                width: 30px;
            }
        }

        .breadcrumbs {
            padding-top: 50px;
        }

        h1 {
            margin: 10px 0;
            font-size: 50px;
        }
    }

    &--no-banner {
        margin-bottom: 0;

        .breakout-content {
            &:before {
                bottom: 30px;
            }

            &:after {
                bottom: 0;
            }
        }
    }
}

.breakout-image-container {
    position: relative;


    &--left {
        margin-right: -8.333%;
    }

    &--right {
        margin-left: -8.333%;
    }
}

.breakout-text-container {
    position: relative;
    background-color: $color-off-white;
    padding: 15px;
    padding-right: 8.333%;

    &:before {
        content: "";
        position: absolute;
        background-color: $color-off-white;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        transform: translateX(-99%);
    }
}

.breakout-image {
    position: absolute;
    height: 100%;
    top: 0;

    &--left {
        right: 0;

        &:after {
            display: block;
            content: "";
            position: absolute;
            height: 100%;
            width: 500%;
            top: 0;
            left: 0;
            transform: translateX(-99%);
            background-color: $color-light-peach;
        }
    }

    &--right {
        left: 0;

        &:after {
            display: block;
            content: "";
            position: absolute;
            height: 100%;
            width: 500%;
            top: 0;
            right: 0;
            transform: translateX(99%);
            background-color: $color-light-peach;
            background-image: url("../../Content/images/logo-silhouette-2.png");
            background-position: 200px -120px;
            background-size: 1500px;
        }
    }
}

.breakout-content {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;

    &--left {
        margin-right: 8.333%;
    }

    &--right {
        margin-left: 8.333%;
    }
}

/*********************Media Queries*********************/

@media only screen and (max-width: 992px) {
    .breakout-container {
        &:before,
        &:after {
            display: none;
        }

        &--left,
        &--right {
            margin-right: 0;
            margin-left: 0;
        }

        &--title-block,
        &--event-title-block {
            margin-left: -20px;
            margin-right: -20px;
            padding: 0 20px;

            .breadcrumbs {
                padding-top: 35px;
            }
        }

        &--title-block {
            height: 175px;
        }

        &--event-title-block {
            height: unset;
            margin-bottom: 0;
        }
    }

    .breakout-content {
        position: static;

        &--left,
        &--right {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .breakout-image-container {
        &--left,
        &--right {
            margin-right: -20px;
            margin-left: -27px;
        }
    }

    .breakout-image {
        &--left,
        &--right {
            right: unset;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .breakout-text-container {
        width: 100%;
        padding: 15px 50px;
    }
}

@media only screen and (max-width: 768px) {

}

@media only screen and (max-width: 543px) {
    .breakout-text-container {
        padding: 0 30px;
    }

    .breakout-container {

        &--title-block {
            height: auto;

            h1 {
                margin: 15px 0 30px;
            }
        }
    }
}