﻿/*hero*/
/*.banner--desktop {
    display: block;
}*/

/*.banner--mobile {
    display: none;
}*/

.hero {
    margin: 70px 0;

    &--event,
    &--contact,
    &--basic {
        margin: 0;
    }

    img {
        position: relative;
    }
}

.hero__container {
    height: 640px;

    &--small {
        height: 370px;

        img {
            max-height: 100%;
        }
    }
}

.hero__image--short {
    &:after {
        display: block;
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        right: 0;
        transform: translateX(99%);
        background-color: $color-light-peach;
        background-size: 1100px !important;
    }
}

.hero__text-container {
    position: relative;
    margin-top: -165px;
}

.hero .arrow-container {
    margin-top: 105px;
}

.hero-carousel-wrapper {
  position: relative;

  .tns-controls button {
    width: 40px;
    height: 40px;
    background-color: transparent;

    &[data-controls='prev'] {
      left: calc(50% - 640px);
    }

    &[data-controls='next'] {
      right: calc(50% - 640px);
    }
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 20px;

    .tns-controls button[data-controls='prev'] {
      left: 0;
    }

    .tns-controls button[data-controls='next'] {
      right: 0;
    }
  }
}

.hero-carousel {
  //

  .hero__container {
    height: auto;
    aspect-ratio: 16 / 9;
  }

  .hero__image img,
  .hero__image video {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .hero__text-container .arrow-container--small {
    top: 2px;
    margin-top: 0;
  }

  .breakout-text-container:before {
    display: none;
  }

  @media screen and (max-width: 991px) {
    .grid {
      padding: 0;
    }

    .hero__text-container {
      margin: 0;
    }
  }
}

/*welcome section*/
.welcome__container {
    height: 890px;
    background-color: $color-cream;

    &:before {
        background-color: $color-cream;
    }

    &:after {
        background-color: $color-cream;
    }

    .divider {
        margin: 0 0 50px;
    }

    .divider--breakout-right {
        margin-top: 0;
    }

    h2 {
        margin-top: 0;
    }

    .event-block__text {
        padding-right: 15px;
    }

    .event-block__title {
        font-size: 28px;
    }

    .h4--small {
        font-size: 24px;
    }
}

.welcome__container.breakout-container--left {
    &:after {
        background-color: transparent;
    }
}

.welcome__container.breakout-container--right {
    &:before {
        background-color: transparent;
    }
}

.welcome__content {
    padding: 75px 0 50px;

    .event-block__link {
        margin-top: -60px;
    }

    img {
        max-width: 100%;
    }
}

.welcome__content--text {
    padding-top: 0;
}

.event-block__text {
    position: relative;
    margin-top: -110px;
    background-color: $color-cream;
    padding-right: 8.333%;
}

.event-block__link {
    font-family: $font-korolev-condensed;
    font-size: 30px;
    color: $color-orange;
    text-transform: uppercase;

    .arrow-container {
        background-color: $color-orange;
    }

    &--bold {
        font-weight: $font-weight-bold;
    }

    &:hover {
        color: darken($color-orange, 3%);

        .arrow-container {
            background-color: darken($color-orange, 3%);
        }

        .arrow {
            fill: $color-light-peach;
        }
    }
}

/*small banner section - strings program and recurring events*/
.small-banner {
    margin: 70px 0;
}

.small-banner__container {
    height: 370px;
}

.small-banner__text-container {
    position: relative;
    margin-top: -100px;
}

.small-banner .arrow-container {
    margin-top: 40px;
}

/*********************Media Queries*********************/

@media only screen and (max-width: 992px) {
    .hero__container {
        height: 640px;

        &--small {
            height: 370px;
        }
    }

    .hero__text-container {
        margin-top: 0;
        margin-left: -20px;
        margin-right: -20px;
    }

    .small-banner__text-container {
        margin-right: -20px;
        margin-left: -20px;
        margin-top: 0;
    }

    .event-block__text {
        width: 83.33%;
        padding-right: 15px;
    }

    .welcome {
        background-color: $color-cream;
    }

    .welcome__container {
        height: auto;
    }

    .welcome__content--text {
        padding-top: 0;
    }
}

@media only screen and (max-width: 768px) {
    /*.banner--desktop {
        display: none;
    }

    .banner--mobile {
        display: block;
    }*/

    .hero__container {
        height: 450px;

        &--small {
            height: 370px;
        }
    }

    .hero {
        margin: 0 0 50px;
    }
}

@media only screen and (max-width: 543px) {
    .hero__container {
        height: 360px;

        &--small {
            height: 360px;
        }
    }

    .welcome__content {
        padding-bottom: 25px;
    }

    .welcome__content--text {
        padding-bottom: 50px;
    }

    .welcome__block {
        margin-bottom: 25px;
    }
}
