﻿.contact__main {
    padding-right: 8.33%;
}

.contact__form {
    text-align: right;
}

.contact__copy {
    margin-top: 70px;
}

.sidebar__title--contact {
    font: $font-weight-light 40px/40px $font-korolev-condensed;
    color: $color-orange;
    margin-bottom: 5px;
}

.sidebar__section--contact {
    p {
        margin: 0;
    }

    a {
        font: $font-weight-light 24px $font-korolev-condensed;
        color: $color-bright-red;

        &:hover,
        &:focus {
            color: $color-medium-red;
        }
    }
}

/*********************Media Queries*********************/

@media only screen and (max-width: 768px) {
    .contact {
        .hero {
            margin: 0;
        }

        .sidebar {
            margin-left: -20px;
            margin-right: -20px;
            padding: 15px 20px;
        }
    }

    .contact__form {
        text-align: left;
    }

    .contact__copy {
        margin-top: 40px;
    }
}