.tns-outer {
  position: relative;
}

.tns-controls {
  //

  button {
    z-index: 1;
    position: absolute;
    top: 25vw;
    transform: translateY(-50%);
    border: 0;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 1em solid $color-medium-red;
      border-top-color: transparent;
      border-bottom-color: transparent;
    }

    &[disabled] {
      opacity: 0;
    }

    &[data-controls='prev'] {
      left: 2em;

      &:before {
        border-left: 0;
      }
    }

    &[data-controls='next'] {
      right: 2em;

      &:before {
        border-right: 0;
      }
    }
  }
}

.tns-nav {
  z-index: 1;
  position: absolute;
  bottom: 0.1875em;
  left: 50%;
  transform: translateX(-50%);

  button {
    width: 1em;
    height: 1em;
    border: 0;
    border-radius: 50%;
    background-color: $primary-color-light;
    font-size: 1rem;

    &:not(:last-of-type) {
      margin-right: 0.5em;
    }

    &.tns-nav-active {
      background-color: $color-medium-red;
    }
  }
}

@include mq(64em) {
  .tns-controls button {
    top: 50%;
  }

  .tns-nav {
    bottom: 1em;
  }
}
