﻿.events-landing {
    .event-block {
        margin: 70px 0 120px;

        img {
            max-width: 100%;
        }
    }
}

.events-landing .event-block__text {
    background-color: $color-off-white;
    padding: 5px 8.33% 10px 0;
    margin-top: -100px;
}

.event-block__title--events-landing {
    line-height: 1.1;
}

.h4--small.event-block__date-time--events-landing {
    margin-top: 5px;
}

.event-block__description--events-landing {
    margin: 10px 0 20px;
}

.events-landing__buttons {

    .btn-container {
        display: block;
        padding-left: 15px;
    }

    .event-block__link--detail {
        margin-bottom: 0;
    }
}

.sponsors {
    margin: 100px 0 120px;
}

.sponsors__logos {
    margin: 25px 25px;
}

.sponsors__description {
    margin-top: 50px;
}

/*********************Media Queries*********************/

@media only screen and (max-width: 992px) {

}

@media only screen and (max-width: 768px) {
    .events-landing {
        .event-block__text {
            width: unset;
            margin-top: 0;
        }
    }
}

@media only screen and (max-width: 543px) {
}