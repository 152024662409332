.c-image-carousel {
    &__carousel {
        padding-inline: 1.5rem;

        @include mq(64em) {
            padding-inline: 3rem;
        }

        .tns-controls {
            button {
                top: 50%;
            
                &[data-controls="prev"] {
                    left: -1.75rem;

                    @include mq(64em) {
                        left: -2.25rem;
                    }

                    @include mq(84em) {
                        left: -3rem;
                    }
    
                    &::before {
                        border-right-color: #75152b;
                    }
                }
    
                &[data-controls="next"] {
                    right: -1.75rem;

                    @include mq(64em) {
                        right: -2.25rem;
                    }

                    @include mq(84em) {
                        right: -3rem;
                    }
    
                    &::before {
                        border-left-color: #75152b;
                    }
                }
            }
        }
    }
}