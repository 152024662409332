﻿.year-pagination {
    margin: 50px 0 25px;
}

.past-event-year {
    display: inline-block;
    padding: 0 10px;
    margin: 10px 0;
    border-right: 1px solid $color-orange;

    a {
        color: $color-orange;
    }

    &:last-child {
        border-right: none;
    }
}

.past-event-year.active {
    font-weight: $font-weight-bold;

    a {
        color: $color-medium-red;
    }
}