﻿.latino-arts-header {
    z-index: 9;
    text-transform: uppercase;    
}

.breakout-container.header {
    height: 172px;
    background-color: $color-cream;

    &:before {
        background-color: $color-cream;
    }

    &:after {
        display: none;
    }
}

.icon-logo {
    max-width: 200px;
    padding: 27px 0;
}

.icon.icon-search {
    height: 22px;
    width: 25px;
    color: $color-brown;

    &--btn {
        color: $color-beige;
    }
}

.nav--top {
    background-color: $color-light-peach;
    height: 52px;
    line-height: 52px;
    margin-left: 8.33%;
    position: relative;

    &:before,
    &:after {
        content: "";
        position: absolute;
        top: 0;
        display: block;
    }

    &:before {
        background-color: $color-peach;
        left: 0;
        width: 15px;
        height: 15px;
        transform: translateX(-100%);
    }

    &:after {
        background-color: $color-light-peach;
        right: 0;
        width: 100%;
        height: 100%;
        transform: translateX(100%);
    }
}

.nav__icon {
    &:hover {
        background-color: rgba(255, 255, 255, .5);
    }
}

.nav--mobile,
.close-menu {
    display: none;
}

.nav--main {
    height: 118px;
    line-height: 118px;
}

.nav__list--main {
    font-size: 28px;
    text-align: right;

    a {
        padding: 0 15px;
    }
}

.nav__list-item {
    font-family: $font-korolev-compressed;

    &:first-child .nav__list-link {
        padding-left: 0;
    }

    &:last-child .nav__list-link {
        padding-right: 0;
    }

    &:nth-child(5n+1) .nav__list-link {
        color: $color-yellow;
    }

    &:nth-child(5n+2) .nav__list-link {
        color: $color-bright-red;
        font-family: $font-korolev;
        font-weight: $font-weight-light;
    }

    &:nth-child(5n+3) .nav__list-link {
        color: $color-dark-red;
        font-weight: $font-weight-bold;
    }

    &:nth-child(5n+4) .nav__list-link {
        color: $color-medium-red;
        font-family: $font-korolev-condensed;
        font-weight: $font-weight-light;
    }

    &:nth-child(5n+5) .nav__list-link {
        color: $color-orange;
        font-family: $font-korolev-condensed;
        font-weight: $font-weight-bold;
    }

    .main-nav-dropdown .nav__list-link {
        color: $color-bright-red;
        font: $font-weight-bold 20px/1.1 $font-korolev-condensed;
        text-align: center;
    }

    &:hover .nav__list-link {
        text-decoration: underline;
    }

    &:hover {
        position: relative;
        .subnav {
            visibility: visible;
            opacity: 1;
        }
    }
}

.has-dropdown {
    position: relative;
}

.subnav {
    background: $color-light-peach;
    padding: 20px 15px;
    text-align: center;
    position: absolute;
    top: 95px;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.25s ease-out;
    transition-delay: .15s;
    visibility: hidden;
    opacity: 0;
    width: 245px;
    z-index: 10;
    line-height: normal;
    box-shadow: 5px 5px 0 #e3cbc9;

    &:before {
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 12px solid $color-light-peach;
        content: '';
        display: inline-block;
        position: absolute;
        top: -10px;
        left: 50%;
        transform: translateX(-50%);
    }
}
.subnav li {
    display: block;
    margin-bottom: 5px;
    line-height: normal;
    color: $color-bright-red;

    &:last-child {
        margin-bottom: 0;
    }
}

.subnav__list-item {
    color: $color-bright-red;
    font: $font-weight-bold 24px/32px $font-korolev-condensed;
    display: inline-block;
    font-size: 18px;
    line-height: 1;
    padding: 5px 0;
    vertical-align: middle;
    width: 100%;

    &:hover {
        color: $color-medium-red;
    }
}

.nav__list .subnav > li {
    display: block;
}

.subnav-trigger {
    display: none;
}

.nav__social-media {
    padding-left: 15px;

    ul {
        display: flex;
        align-items: center;
        gap: 15px;

        li {
            padding: 0 !important;
        }
    }

    .social-media-link {
        padding: 0;

        .icon {
            color: inherit;
            margin-left: 0;
        }
    }
}

/*********************Media Queries*********************/

@media only screen and (max-width: 1150px) {
    .nav--top {
        // margin-left: 16.66%;
    }

    .nav__list--main {
        font-size: 26px;
    }

    .nav__list--main a {
        padding: 0 10px;
    }
}

@media only screen and (max-width: 992px) {

    #outer-container {
        margin-top: 125px;
    }

    .icon-logo {
        max-width: 175px;
        max-height: 125px;
    }

    .latino-arts-header {
        background-color: $color-light-peach;
        position: fixed;
        width: 100%;
        top: 0;
    }

    .breakout-container.header {
        height: auto;
        background-color: initial;
    }

    .nav__list--main {
        font-size: 27px;
    }

    .nav--mobile,
    .close-menu {
        display: block;
    }

    .menu-trigger {
        width: 35px;
    }

    .header__hamburger,
    .header__close {
        transition: all 300ms;
        width: 35px;
    }

    .header__hamburger {
        margin-left: calc(100% - 35px);
        margin-right: 0;

        span {
            display: block;
            width: 35px;
            height: 4px;
            margin: 8px 0;
            background-color: $color-bright-red;

            &:first-child {
                background-color: $color-orange;
            }

            &:last-child {
                background-color: $color-yellow;
            }
        }
    }

    .header__close {
        background-color: $color-light-peach;
        fill: $color-medium-red;
        border-radius: 50%;
        padding: 8px;
        height: 35px;
        position: absolute;
        left: 0;        
        top: 20px;
        transform: translateX(10px);
        z-index: 501;
    }    

    .nav-container,
    .nav-mask {
        transition: all 250ms;
    }

    .header__close,
    .nav-mask {
        display: none;
    }

    .show-menu {
        .header__close,
        .nav-mask {
            display: block;
        }

        .nav-container {
            transform: translateX(0);
        }
    }    

    .nav-mask {
        background: rgba(0, 0, 0, .65);
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 500;
        transform: translateX(0);
    }

    .nav-container {
        background-color: $color-light-peach;
        overflow-y: auto;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        margin-right: -20px;
        padding-left: 0;
        z-index: 501;
        display: flex;
        flex-direction: column;        
        width: 300px;
        transform: translateX(100%);
    }

    .nav-inner-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;

        /* for Firefox */
        min-height: 0;
    }

    .subnav {
        background-color: $color-peach;
        position: static;
        width: auto;
        box-shadow: initial;
        padding: 5px 0;
        text-align: left;
        transform: translateX(0);
        margin-left: -12px;
        margin-right: -7px;
        display: none;

        &:before {
            display: none;
        }
    }

    .subnav-trigger {
        background: none;
        border: none;
        display: block;
        height: 49px;
        padding: 0 25px;
        position: absolute;
        right: 0;
        top: 0;
        transition: all 300ms;

        &--down {
            &:after {
                transform: rotate(90deg);
            }
        }

        &:before {
            border-left: 1px solid $color-beige;
            content: '';
            height: 80%;
            position: absolute;
            top: 10%;
            right: 98%;
        }

        &:after {
            @include arrow-right('930c30');
            background-size: contain;
            content: '';
            display: inline-block;
            height: 14px;
            transition: all 300ms;
            width: 8px;
        }
    }

    .nav__list--main a {
        padding: 0;
    }

    .subnav--show.subnav {
        display: block;
        visibility: visible;
        opacity: 1;
    }

    .subnav__list-item {
        color: $color-medium-red;
        font-weight: $font-weight-normal;
    }

    .nav--top,
    .nav--main {
        height: auto;
        line-height: 1;
        background-color: initial;        
    }

    .nav--main li {
        border-bottom: 1px solid $color-beige;

        &:last-child {
            border-bottom: none;
        }
    }

    .top-nav-list-item {
        border-bottom: 1px solid $color-beige;

        &:last-child {
            border-bottom: none;
        }
    }

    .nav--top {
        border-top: 1px solid $color-orange;
        margin-left: 0;

        .nav__item--top,
        .nav__icon{
            padding: 15px 0;
        }

        &:before,
        &:after {
            display: none;
        }
    }    

    .header .nav__list {
        text-align: left;
        padding: 10px 0;
    }

    .header .nav__list li {
        display: block;
        padding: 0 12px;        
    }

    .header .nav__list-item,
    .header .nav__list-item .nav__list-link,
    .header .nav__item--top,
    .nav-icon {
        font-family: $font-korolev-condensed;        
    }

    .header .nav__list-item,
    .header .nav__list-item .nav__list-link {
        color: $color-medium-red;
        font-weight: $font-weight-bold;
    }

    .header .nav__list-link {                
        display: inline-block;
        font-size: 18px;
        line-height: 1;
        padding: 15px 0;
    }

    .header .nav__item--top,
    .nav__icon--top {
        color: $color-orange;
        font-size: 16px;
        display: inline-block;
    }

    .has-dropdown a {
        position: relative;
    }   

    .mobile-utility-nav {
        justify-content: flex-end;
    }

    .nav__icon--mobile {
        margin-right: 20px;

        .icon.icon-search {
            height: 32px;
            width: 32px;
            margin-top: 6px;
        }
    }

    .nav__social-media {
        margin-top: 15px;
    }
}

@media only screen and (max-width: 768px) {
    .mobile-utility-nav {
        padding-right: 20px;
    }
}

@media only screen and (max-width: 543px) {
    #outer-container {
        margin-top: 115px;
    }

    .icon-logo {
        max-height: 115px;
    }

    .nav-container {
        width: 275px;
    }    
}

@media only screen and (min-width: 993px) {
    .nav__list--top {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
    }
}