﻿.umb-grid {
    margin-bottom: 50px;

    img {
        max-width: 100%;
    }

    .row {
        max-width: 100%;
        margin: 0 auto;
    }
}

.umb-grid {
    h1, h2, h3, h4, h5, h6 {
        font: $font-weight-bold 48px/1.2 $font-korolev-condensed;
        color: $color-dark-red;
    }

    h2 {
        font-size: 48px;
    }

    h3 {
        font-size: 38px;
    }

    h4 {
        font-size: 32px;
    }

    h5 {
        font-size: 28px;
    }

    h6 {
        font-size: 22px;
    }
}

/*********************Media Queries*********************/

@media only screen and (max-width: 992px) {
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 543px) {

}