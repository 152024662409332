input,
textarea {
    font: $font-weight-light 18px/1.2 $font-korolev;
}

input {
    text-transform: none;
}

::-webkit-input-placeholder { /* WebKit browsers */
    text-transform: uppercase;
}

:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    text-transform: uppercase;
}

::-moz-placeholder { /* Mozilla Firefox 19+ */
    text-transform: uppercase;
}

:-ms-input-placeholder { /* Internet Explorer 10+ */
    text-transform: uppercase;
}

::placeholder { /* Recent browsers */
    text-transform: uppercase;
}

.form-input {
    background-color: #fff;
    width: 100%;
    max-width: 100%;
    height: 65px;
    line-height: 65px;
    border: 4px solid $color-light-peach;
    padding: 15px;
    margin-bottom: 20px;

    &--comments {
        height: 185px;
        line-height: unset;
    }
}

.form-label {
    text-transform: uppercase;
    font: $font-weight-light 24px/24px $font-korolev;
}

.panel-search {
    width: 100%;

    .form-control {
        width: 90%;
        min-height: 60px;
        padding: 0 12px;
        border: 1px solid #cdcdcd;
        vertical-align: middle;
    }
}

/*************custom radio button*************/
.radio-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #fff;
    border: 4px solid $color-light-peach;
    border-radius: 50%;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate3d(-55%, -55%, 0);
    border-radius: 50%;
    background: $color-bright-red;
}

.umbraco-forms-form {
    input[type="submit"],
    fieldset {
        margin-top: 25px;
    }

    input[type="submit"] {
        cursor: pointer;
    }

    fieldset {
        max-width: 900px;
    }

    .field-validation-error {
        color: $color-bright-red;
        display: block;
    }

    .form-group {
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 992px) {
    .panel-search {
        text-align: center;

        .form-control {
            width: 75%;
        }
    }
}