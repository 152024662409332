.c-banner {
  position: relative;
  display: flex;
  flex-direction: column; // required to make large banners work on small screens
  padding-bottom: 2em;
  color: $color-cream;
}

.c-banner--small {
  z-index: 1;
  flex-direction: row;
  align-items: center;
  height: 0; // fixes vertical centering issue in IE 11
  min-height: 16em;
  padding: 1em;

  &:before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(black, 0.3);
  }
}

.c-banner__image {
  height: 576px;  
  margin-left: 51%;
  width: 768px;
  
  
  @media screen and (min-width: 64em) {    
    width: 504px;
    height: 648px;
  }

  @media screen and (min-width: 100em) {
    width: 1080px;
    height: 960px;    
  }
}

.c-banner__content-wrapper {
  margin-top: 2em;
  padding: 2em 1em;
  background-color: rgba($color-cream, 0.9);
}

.c-banner__content {
  //

  h1 {
    font-size: 2.8em;
    color: $color-medium-red
  }

  p {
    font-size: 1em;
    color: $color-bright-red
  }

  a {
    color: $color-orange;
  }
}

///

@include mq(64em) {
  .c-banner {
    padding-bottom: 0;
  }

  // .c-banner__image {
  //   width: 550px;
  //   //height: 40em;    
  //   //border: solid 3px red;
  // }

  .c-banner__content-wrapper {
    position: absolute;
    top: 50%;
    right: calc(46% - 4em);
    left: calc(14% - 4em);
    transform: translateY(-50%);
    margin-top: 0;
    padding: 2em 0 3em 4em;
  }

  .c-banner__content {
    max-width: 30em;
  }
}
