﻿.event-schedule-container {
    margin-bottom: 100px;
}

.schedule__season-title {
    font: $font-weight-light 40px/1.2 $font-korolev;
    color: $color-orange;
    margin: 70px 0 40px;
}

.schedule__category {
    font: $font-weight-bold 24px/1.2 $font-korolev;
    color: $color-bright-red;
}

.schedule-block {
    margin: 20px 0;

    img {
        max-width: 100%;
    }
}

.schedule-block__title {
    font-size: 30px;
    color: $color-orange;
}

.schedule-block__date-time {
    font-size: 26px;
}

/*********************Media Queries*********************/

@media only screen and (max-width: 1230px) {
    .schedule-block__title {
        font-size: 24px;
    }

    .schedule-block__date-time {
        font-size: 22px;
    }
}

@media only screen and (max-width: 992px) {
    .schedule {
        h1 {
            padding-bottom: 30px;
        }
    }

    .schedule__season-title {
        margin: 50px 0 25px;
    }    
}

@media only screen and (max-width: 768px) {

}

@media only screen and (max-width: 543px) {
}