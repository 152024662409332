@mixin display-grid {
    display: grid;
    grid-template-rows: auto;
    grid-gap: 30px;   
}

@mixin arrow-right($color) {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5.73 9.92'%3E%3Cpath fill='none' stroke='%23#{$color}' stroke-miterlimit='10' d='M.48.35l4.55 4.54L.35 9.56'/%3E%3C/svg%3E");
}

@mixin mq($value, $property: min-width, $media: screen) {
    @media #{$media} and ($property: $value) {
      @content;
    }
  }