﻿.latino-arts-footer {
    overflow: hidden;
    text-transform: uppercase;
    margin-top: 50px;
}

footer ul li {
    display: inline-block;
}

.footer--events {
    height: 521px;
    background-color: $color-cream;

    &:before {
        background-color: $color-cream;
    }
}

.footer__event-section {
    padding: 75px 0;

    h3 {
        font-size: 26px;
    }

    h4 {
        font-size: 22px;
    }

    img {
        max-width: 100%;
    }
}

.footer--nav {
    height: 52px;
    line-height: 52px;
    background-color: $color-light-peach;

    &:before {
        background-color: $color-light-peach;
    }

    &:after {
        background-color: $color-cream;
    }
}

.social-media-link {
    padding-left: 35px;

    .icon {
        margin-left: 5px;
    }
}

.footer--contact {
    height: 46px;
    line-height: 46px;
    background-color: $color-peach;
    font-family: $font-korolev;
    font-size: 14px;

    a {
        padding: 15px 10px;
    }

    &:before {
        background-color: $color-light-peach;
    }

    &:after {
        background-color: $color-peach;
    }
}

.copyright {
    padding: 0 15px;
    margin: 0;
}

.footer__link {
    color: $color-dark-red;
}

.footer__info--site ul {
    margin-right: 15px;
}

/*********************Media Queries*********************/

@media only screen and (max-width: 992px) {
    .latino-arts-footer {
        margin-top: 0;
        background-color: $color-cream;
    }

    .footer {
        margin-left: -20px;
        margin-right: -20px;

        .breakout-content {
            margin: 0 20px;
        }

        .nav__item--top,
        .nav__icon--top,
        .social-media-link {
            padding: 0 8px;
            font-size: 18px;
        }
    }

    .footer__content {
        margin-bottom: 25px;
    }

    .footer--events {
        height: auto;
    }

    .footer__event-section {
        padding-bottom: 25px;

        h3 {
            font-size: 32px;
        }

        h4 {
            font-size: 28px;
        }

        h5 {
            font-size: 20px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .footer {
        .nav__item--top,
        .nav__icon--top,
        .social-media-link {
            padding: 6px 15px;
        }
    }

    .footer__nav,
    .footer__social-media {
        text-align: center;
        margin: 0 auto;

        li {
            padding: 3px 0;
        }
    }

    .footer__info--contact {
        width: 100%;

        ul {
            padding-top: 15px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        li {
            text-align: center;
        }
    }

    .footer__info--site {
        margin: 0 auto;
        padding-bottom: 15px;

        ul {
            margin-right: 0;
        }
    }

    .footer--contact a {
        padding: 8px 10px;
    }

    .footer--nav,
    .footer--contact {
        height: auto;
        line-height: unset;
    }

    .footer__event-section {
        padding-bottom: 25px;

        h3 {
            font-size: 30px;
        }

        h4 {
            font-size: 24px;
        }

        h5 {
            font-size: 18px;
        }
    }
}

@media only screen and (max-width: 543px) {
    .footer {
        .nav__item--top,
        .nav__icon--top,
        .social-media-link {
            padding: 5px 5px;
        }
    }

    .footer__nav,
    .footer__social-media {
        padding: 0;
    }
}