﻿.event-info {
    padding-right: 60px;
}

.sidebar {
    background-color: $color-cream;
    padding: 40px 55px;
    position: relative;
    margin-top: -35px;

    &:after {
        display: block;
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        right: 0;
        transform: translateX(99%);
        background-color: $color-cream;
    }
}

.sidebar__section {
    margin-bottom: 50px;

    &--contact {
        margin-bottom: 10px;
    }
}

.date__time {
    margin: 0 0 10px;
    font-family: $font-korolev-compressed;
    font-size: 40px;
    color: $color-orange;
    font-weight: $font-weight-normal;
}

.date__time--sub {
    font-family: $font-korolev-condensed;
    font-size: 24px;
    color: $color-charcoal;
    font-weight: $font-weight-light;
}

.exhibit-date-time {
    font-size: 24px;
    font-weight: $font-weight-bold;
    margin-top: 0;
}

.event__description {
    margin: 70px 0;
}

.event-block__link--detail {
    font-size: 28px !important;
    display: block;
    margin-bottom: 25px;
    line-height: 1;
}

.la-address {
    font: $font-weight-light 24px $font-korolev-condensed;
    text-transform: uppercase;
}

.directions__location {
    font: $font-weight-normal 18px $font-korolev-condensed;
    text-transform: uppercase;
}

.twitter-share-button {
    margin: 0;
    vertical-align: bottom;
}

.fb-share-button > span {
    vertical-align: unset !important;
}

/*********************Media Queries*********************/

@media only screen and (max-width: 1230px) {
}

@media only screen and (max-width: 992px) {
    .date__time {
        font-size: 35px;
    }

    .sidebar {
        padding: 20px 35px;
    }

    .event__rte {
        iframe {
            max-width: 100%;
        }
    }
}

@media only screen and (max-width: 768px) {
    .date__time {
        font-size: 30px;
    }

    .sidebar {
        padding: 15px 20px;
        margin-top: 40px;

        &:after {
            display: none;
        }
    }

    .hero.hero--event {
        margin-bottom: 25px;
    }

    .event-info {
        padding-right: 15px;
    }

    h4.date__time--sub {
        margin-bottom: 10px;
    }

    .event__description {
        margin: 0;
    }    
}

@media only screen and (max-width: 543px) {
}
