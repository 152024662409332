button {
    cursor: pointer;
}

a {
    text-decoration: none;
    text-transform: uppercase;
    transition: all 300ms;
    color: $color-bright-red;

    &:hover,
    &:focus {
        color: $color-medium-red;
    }
}

 /*Buttons*/
.btn {
    position: relative;
    background: $color-light-peach;
    color: $color-bright-red;
    font: $font-weight-normal 30px/1.2 $font-korolev-condensed;
    border: none;
    text-transform: uppercase;
    display: inline-block;
    padding: 12px 25px;
    min-width: 230px;
    text-align: center;
    vertical-align: middle;
    transform: perspective(1px) translateZ(0);
    transition: all 300ms;

    &:active {
        border-color: darken($color-light-peach, 10%);
        transform: translateX(0);

        &:before {
            background: darken($color-light-peach, 10%);
        }
    }

    &:hover,
    &:focus {
        color: $color-medium-red;
    }
}

.btn--search {
    background-color: $color-bright-red;
    border: none;
    transition: all 300ms;
    margin-left: -6px;
    margin-top: -1px;
    min-height: 60px;
    min-width: 60px;
    vertical-align: middle;

    &:hover {
        background-color: $color-medium-red;
    }
}

.arrow-container {
    position: relative;
    display: inline-block;
    background-color: $color-bright-red;
    width: 60px;
    height: 60px;
    transition: all 400ms ease-out 0s;

    &--large {

        &:before,
        &:after {
            content: "";
            position: absolute;
            display: block;
        }

        &:before {
            top: 0;
            left: 0;
            width: 30px;
            height: 30px;
            transform: translateY(-100%);
            background-color: $color-peach;
        }

        &:after {
            bottom: 0;
            right: 0;
            width: 15px;
            height: 15px;
            transform: translateX(100%);
            background-color: $color-beige;
        }
    }

    &--medium {
        width: 25px;
        height: 25px;
    }

    &--small {
        width: 21px;
        height: 21px;
    }

    &--red {
        background-color: $color-bright-red !important;
    }

    &--btn {
        position: absolute;
        top: 0;
        left: 99%;
    }

    &:hover {
        background-color: darken($color-bright-red, 3%);
    }
}

.arrow-container.pattern-1,
.arrow-container.pattern-2 {
    .arrow {
        fill: $color-peach;
    }

    &:before {
        top: 0;
        left: 0;
        transform: translateY(-100%);
    }

    &:after {
        bottom: 0;
        right: 0;
        transform: translateX(100%);
    }
}

.arrow-container.pattern-1 {
    background-color: $color-orange;

    &:before {
        width: 15px;
        height: 15px;
        background-color: $color-yellow;
    }

    &:after {
        width: 30px;
        height: 30px;
        background-color: $color-beige;
    }

    &:hover {
        background-color: darken($color-orange, 3%);
    }
}

.arrow-container.pattern-2 {
    background-color: $color-yellow;

    &:before {
        width: 30px;
        height: 30px;
        background-color: $color-bright-red;
    }

    &:after {
        width: 15px;
        height: 15px;
        background-color: $color-light-peach;
    }

    &:hover {
        background-color: darken($color-yellow, 3%);
    }
}

.arrow {
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    left: 50%;
    width: 16px;
    height: 25px;
    fill: $color-yellow;
    transition: all 200ms ease;

    &--medium {
        width: 9px;
        height: 15px;
    }

    &--small {
        width: 8px;
        height: 12px;
    }
}

.btn-container {
    .arrow-container {
        vertical-align: middle;
        margin-left: -5px;

        &:before,
        &:after {
            background: none;
        }
    }

    &:hover,
    &:focus {
        .arrow-container {
            background-color: darken($color-bright-red, 3%);
        }
    }
}

/*********************Media Queries*********************/

@media only screen and (max-width: 992px) {
}

@media only screen and (max-width: 768px) {

}

@media only screen and (max-width: 543px) {
    .btn {
        font-size: 24px;
        padding: 12px 10px;
        min-width: 200px;
    }

    .arrow-container {
        width: 45px;
        height: 52px;

        &--small {
            width: 21px;
            height: 21px;
        }
    }
}
