.c-image-text {
    --gap: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap);

    &__image,
    &__text {
        flex-shrink: 0;
        width: 100%;
        max-width: 100%;

        @include mq(48em) {
            flex: 0 0 auto;
        }
    }

    &__image {
        @include mq(48em) {
            width: calc(45% - (1 / 2 * var(--gap)));
        }

        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    &__text {
        align-self: center;
        
        @include mq(48em) {
            width: calc(55% - (1 / 2 * var(--gap)));
        }

        h2 {
            line-height: 1.2;
        }

        .rte {
            p, ol, ul {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &--has-text-background {
        position: relative;
        z-index: 1;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            background-color: #fff8f0;
        }
        
        .c-image-text__text {
            background-color: #fff8f0;
            padding: 0.5rem 1.5rem 2rem;
        }

        @include mq(48em) {
            padding-block: 2.5rem;

            &::before {
                width: 70%;
            }

            .c-image-text__text {
                padding: 0 2rem 0 0.5rem;
            }
        }
    }

    &--reverse {
        flex-direction: row-reverse;

        &.c-image-text--has-text-background {
            @include mq(48em) {
                &::before {
                    right: auto;
                    left: 0;
                }

                .c-image-text__text {
                    padding-inline: 2rem 0.5rem;
                }
            }
        }
    }
}