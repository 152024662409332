.rte {
    font-size: 18px;
    line-height: 1.8;

    .intro {
        font-size: 24px;
        font-weight: 700;
        line-height: 1.6;
    }

    a {
        color: $color-bright-red;
        text-transform: none;
        font-weight: $font-weight-bold;

        &:hover {
            color: $color-medium-red;
        }
    }
}

.rte h1,
.rte h2,
.rte h3,
.rte h4,
.rte h5,
.rte h6 {
    margin-top: 30px;
    margin-bottom: 10px;
    font-weight: $font-weight-bold;
}

.rte h1:first-child,
.rte h2:first-child,
.rte h3:first-child,
.rte h4:first-child,
.rte h5:first-child,
.rte h6:first-child {
    margin-top: 15px;
}

.rte {

    h1, h2, h3, h4, h5, h6 {
        text-transform: uppercase;
        line-height: 1;
        margin-top: 30px;
        margin-bottom: 10px;
    }

    h1 {
        color: #75152b;
        font-size: 52px;
    }

    h2 {
        color: #c87622;
        font-size: 42px;
    }

    h3 {
        color: #930c30;
        font-size: 36px;
    }

    h4 {
        color: #75152b;
        font-size: 28px;
    }

    h5 {
        color: #c87622;
        font-size: 22px;
    }

    h6 {
        color: #930c30;
        font-size: 16px;
    }

    .alignleft {
        float: left;
        height: auto;
        margin: 5px 15px 5px 0;
    }

    .alignright {
        float: right;
        height: auto;
        margin: 5px 0 5px 15px;
    }

    .aligncenter {
        text-align: center;
        margin-bottom: 15px;
    }
	
	ol,
	ul {
		padding-left: revert;
	}
		
	li {
		list-style: unset;
	}
}

.rte p {
    margin-bottom: 20px;
    margin-top: 10px;
}

.rte img {
    max-width: 100%;
    height: auto !important;

    /*&[style="float:right;"] {
        margin-bottom: 15px;
        margin-left: 15px;
    }*/
}

.rte iframe {
    margin-top: 10px;
    max-width: 100%;
}

.rte hr {
    border: none;
    border-top: 1px solid #ccc;
    margin-bottom: 40px;
    margin-top: 40px;
}
