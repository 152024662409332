.c-cta-banner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 240px;
    background-color: #75152b;
    color: #fff;
    text-align: center;
    padding: clamp(3rem, 7%, 4rem) clamp(1.5rem, 7%, 3rem);

    &__inner {
        position: relative;
        z-index: 1;
        width: 100%;
    }

    &__heading {
        margin-block: 0;
        line-height: 1.2;
        font-weight: 600;
        color: inherit;

        .h2 {
            color: inherit;
        }
    }

    &__bg-image,
    &__bg-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__bg-image {
        object-fit: cover;
    }

    &__bg-overlay {
        background-color: #000;
    }
}